import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";


export const setCurrentPageButtons = (
	backButton: any,
	buttons: Array<any>
): void => {
	store.dispatch(Actions.SET_PAGE_INFO_ACTION, {
		backButton: backButton,
		pageButtons: buttons
	});
};